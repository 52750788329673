import React from 'react';
import { Remark, RemarkProps } from 'react-remark';
import { AutoLink } from './defaultComponents';

interface IMarkdownProps {
  /** map from html tag to react component to be used
   * ex: ```{
   * a: MyLinkComponent,
   * h1: MyHeaderTitle
   * }```
   */
  components?: RemarkProps['rehypeReactOptions']['components'];
  /** props that go directly to the Remark component, try to avoid them so that it's possible to change the implementation */
  remarkProps?: RemarkProps;
  children: string;
}

const Markdown: React.FC<IMarkdownProps> = ({ components, remarkProps, children }) => {
  return (
    <Remark
      {...remarkProps}
      rehypeReactOptions={{
        ...remarkProps?.rehypeReactOptions,
        components: {
          ...remarkProps?.rehypeReactOptions?.components,
          a: AutoLink,
          ...components
        }
      }}
    >
      {children}
    </Remark>
  );
};

export { Markdown };
